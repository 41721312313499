@media (min-width: 700px) {
  .select_div {
    position: absolute;
    z-index: 1 !important;
  }
}

.select_div {
  z-index: 1 !important;
}

.carSelection_box_inner {
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
}

.carSelection_dropbox_item:hover {
  background: #f4f4f4;
}

.carSelection_dropbox {
  z-index: 100;
  position: absolute;
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  left: 0px;
}

.carSelection_dropbox_inner {
  border: 1px solid #d8d8d8;
  overflow-y: auto;
  max-height: 280px;
}

.carSelection_dropbox_inner_box {
  background-color: #fafafa;
  color: #000000;
  font-size: 14px;
  line-height: 50px;
  text-align: center;
  animation: slide-up 0.4s ease;
  -webkit-animation: slide-up 0.4s ease;
  opacity: 1;
  -webkit-overflow-scrolling: touch;
}

.z-index-top {
  z-index: 10;
}
