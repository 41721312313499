/* Basic */
@keyframes slide-up {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
html {
  overflow-y: scroll;
}
.modal-open {
  padding-right: 0 !important;
}
* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  vertical-align: top;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
input[type='text'],
input[type='tel'],
input[type='number'],
input[type='email'],
input[type='password'] {
  padding: 15px;
  border: 1px solid #d1d1d1;
}
::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #aeaeae;
  opacity: 1; /* Firefox */
}
:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #ccc;
}
::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #ccc;
}
a,
a:hover {
  color: #000;
  cursor: pointer;
  text-decoration: none;
}
button,
button:hover,
button:focus {
  outline: none !important;
  box-shadow: none !important;
}
ul {
  margin: 0;
  padding: 0;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
html {
  height: 100%;
  scroll-behavior: smooth;
}
body {
  font-family: 'Noto Sans KR', sans-serif;
  position: relative;
  min-height: 100%;
}
.wrap {
  width: 100%;
}
.container {
  position: relative;
}

/* buttons */
.btn {
  display: inline-block;
  line-height: 48px;
  padding: 0 20px;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  border-radius: 0;
}
.btn_line_red {
  background-color: #fff;
  border: 1px solid #ff0000;
  color: #ff0000;
}
.btn_line_red:hover,
.btn_line_red:focus {
  background-color: #ff0000;
  border-color: #dd1c1c;
  color: #fff;
}
.btn_line_blue {
  background-color: #fff;
  border: 1px solid #00b5f0;
  color: #00b5f0;
}
.btn_line_blue:hover,
.btn_line_blue:focus {
  background-color: #00b5f0;
  border-color: #0096c7;
  color: #fff;
}
.btn_position {
  margin-left: 210px;
}
.btn_black {
  background-color: #000;
  border: 1px solid #000;
  color: #fff;
}
.btn_black:hover,
.btn_black:focus {
  background-color: #222;
  border-color: #222;
  color: #fff;
}
.btn_line {
  background-color: transparent;
  border: 1px solid #000;
  color: #000;
}
.btn_line:hover,
.btn_line:focus {
  background-color: #fafafa;
}
.btn_transparent {
  background-color: transparent;
  border: 1px solid transparent;
  color: #000;
  text-decoration: underline;
}
.btn_transparent:hover,
.btn_transparent:focus {
  text-decoration: underline;
}
.package_btn_red {
  background-color: #ff0000;
  border: 1px solid #ff0000;
  color: #fff;
  text-align: center;
  cursor: pointer;
}
.package_btn_red:hover,
.package_btn_red:focus {
  background-color: #e50000;
  border-color: #e50000;
  color: #fff;
  text-align: center;
}
.package_btn_blue {
  background-color: #00b5f0;
  border: 1px solid #00b5f0;
  color: #fff;
  text-align: center;
  cursor: pointer;
}
.package_btn_blue:hover,
.package_btn_blue:focus {
  background-color: #0096c7;
  border-color: #0096c7;
  color: #fff;
  text-align: center;
}
.btn_red {
  background-color: #ff0000;
  border: 1px solid #ff0000;
  color: #fff;
  text-align: center;
}
.btn_red:hover,
.btn_red:focus {
  background-color: #e50000;
  border-color: #e50000;
  color: #fff;
  text-align: center;
}
.btn_blue {
  background-color: #00b5f0;
  border: 1px solid #00b5f0;
  color: #fff;
  text-align: center;
}
.btn_blue:hover,
.btn_blue:focus {
  background-color: #0096c7;
  border-color: #0096c7;
  color: #fff;
  text-align: center;
}
.btn_sm {
  line-height: 44px;
  font-size: 13px;
}
.btn_lg {
  font-size: 18px;
}
.shop_result_unit .consulting_button {
  width: 48%;
  margin: 0px 0px 10px 10px;
}

/* Animation Button */
.button-hand:before {
  content: '👇';
  font-size: 60px;
  transform: scaleX(-1);
  right: 0px;
  top: -68px;
  animation: up-down 1s infinite;
  color: #0096c7;
  position: absolute;
}
.button-hand .hands:before,
.button-hand .hands:after {
  content: '👇';
  font-size: 40px;
  opacity: 0;
  transition: 0.4s ease-in-out;
  position: absolute;
}
.button-hand .hands:before {
  transform: rotate(-60deg);
  left: -45px;
  top: -10px;
  position: absolute;
}
.button-hand .hands:after {
  transform: rotate(170deg);
  right: 30px;
  top: 50px;
  position: absolute;
}

@keyframes up-down {
  50% {
    margin-top: -20px;
  }
}

/* header nav */
header .nav_link {
  display: block;
  height: 40px;
  width: fit-content;
  color: #000;
  padding: 5px 0;
  margin: 0 auto 10px;
  border-bottom: 5px solid #fff;
  font-size: 16px;
  font-weight: 500;
}
header .nav_link_other {
  display: inline-block;
  height: 70px;
  width: fit-content;
  color: #000;
  padding: 20px 10px;
  margin: 0 auto 10px;
  border-bottom: 5px solid #fff;
  font-size: 16px;
  font-weight: 500;
}
header .nav_link_home {
  display: inline-block;
  height: 70px;
  width: fit-content;
  color: #000;
  padding: 20px 20px;
  margin: 0 auto 10px;
  border-bottom: 5px solid #fff;
  font-size: 16px;
  font-weight: 500;
}
header .nav_link:last-child {
  margin-bottom: 0;
}
header .nav_link_home:hover,
header .nav_link_home:focus {
  border-bottom-color: #00b5f0;
}

header .nav_link:hover,
header .nav_link:focus {
  border-bottom-color: #00b5f0;
}
header .nav_link_other:hover,
header .nav_link_other:focus {
  border-bottom-color: #00b5f0;
}

.navbar {
  padding: 0;
}
.navbar > .container {
  display: block;
  height: 70px;
}
.navbar-brand {
  padding: 17px 0 0;
  margin: 0;
}
.navbar-brand_header_2 {
  position: absolute;
  padding: 25px 0 0;
  margin: 0;
  left: 10px !important;
}
.navbar-brand img {
  width: 144px;
  height: 16px;
  object-fit: contain;
}
.navbar-brand_header_2 img {
  width: 144px;
  height: 16px;
  object-fit: contain;
}
.navbar-toggler {
  padding: 0;
  top: 20px;
  right: 15px;
}
.navbar-toggler-icon {
  height: 28px;
}

/* banner */
.banner_container .headline.font-en {
  font-size: 40px;
  line-height: 55px;
  font-weight: 700;
}
.banner_container .headline {
  font-size: 36px;
  line-height: 50px;
  font-weight: 700;
  word-break: keep-all;
}
.banner_container .sub {
  margin-top: 20px;
  font-size: 12px;
  font-weight: 300;
  word-break: keep-all;
}
.banner_container .sub ~ .sub {
  margin-top: 10px;
}
.banner_container p,
.info_tab_container .d-table-cell p {
  padding-left: 10%;
}
.banner_container .row .col-md-6,
.info_tab_container .row .col-md-6 {
  margin-bottom: 50px;
}

.package_area {
  margin-top: 100px;
}
.package_area .row {
  margin-left: 5px;
}
.package_button {
  margin-right: 45px;
  margin-top: -6px;
  margin-bottom: 45px;
  width: 304px;
  height: 65px;
  font-size: 20px;
  font-weight: 400;
  line-height: 60px;
}

.carousel-control-inside,
.carousel-control-outside {
  position: absolute;
  top: auto;
  left: auto;
  right: 0;
  bottom: 0;
  width: 100px;
  height: 30px;
  text-align: center;
  vertical-align: middle;
  background: #ff0000;
  border: 1px solid #ff0000;
  color: #fff;
  opacity: 1;
}
.carousel-control-outside {
  right: 101px;
}
.carousel-control-deselected {
  opacity: 0.5;
}
.banner_slide_indicators {
  bottom: -40px;
  margin: 0;
  /*display: none;*/
}
.banner_slide_indicators li {
  flex: 1;
  -ms-flex: 1;
  text-align: center;
  text-indent: 0;
  background-color: transparent;
  height: fit-content;
  font-size: 12px;
}
.banner_slide_indicators li > div {
  display: inline-block;
  padding-bottom: 4px;
  border-bottom: 5px solid transparent;
}
.banner_slide_indicators li.active > div {
  border-bottom-color: #ff0000;
}
.carousel-indicators li::before {
  width: 0;
  height: 0;
}
.carousel-indicators li::after {
  width: 0;
  height: 0;
}

.home_prom_back .banner_container .col-12 {
  min-height: 390px;
}
.home_prom_back .banner_container .col-12:first-child img {
  position: absolute;
  bottom: -100px;
  right: -150px;
}
.absolute_headline {
  position: absolute;
  top: 0;
  right: 0;
  padding: 55px 50px;
  text-align: left;
}
.absolute_headline p {
  padding-left: 0 !important;
}
.more_link {
  display: block;
  margin-top: 25px;
  font-size: 20px;
  font-weight: 700;
  color: #ff0000;
}
.more_link img {
  display: inline-block;
  margin-left: 5px;
  transition: 0.2s;
}
.more_link:hover img,
.more_link:focus img {
  margin-left: 10px;
}

/* promotion */
.quote_btn {
  text-align: center;
}
.promo_why {
  text-align: center;
}
.promo_why img {
  width: 50px;
  margin-bottom: 36px;
}
.promo_why p {
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  margin-bottom: 100px;
  padding: 0;
}
.lluma_table thead tr th,
.lluma_table tbody tr td {
  padding: 16px 0;
  font-size: 18px;
  font-weight: 300;
  text-align: center;
}
.lluma_table thead tr th {
  padding: 22px 0;
  background-color: #000;
  color: #fff;
  font-weight: 500;
}
.lluma_table tbody {
  border-bottom: 1px solid #8c8c8c;
}
.lluma_table_m {
  width: auto !important;
  min-width: 100%;
}
.lluma_table_m thead tr th,
.lluma_table_m tbody tr td {
  font-size: 14px;
  line-height: 22px;
}

.promo_table {
  width: 1200px;
}

.promo_table .td_gray_1 {
  color: #8c8c8c;
  weight: 300;
}
.promo_table .td_gray_2 {
  color: #000000;
  text-decoration: line-through;
}
.promo_table tbody tr td {
  border-bottom: 1px solid #d7d7d7;
}
.promo_table tbody tr:last-child td {
  border-bottom: 0 !important;
}
.promo_table tbody tr td:first-child {
  font-weight: 700;
  border-bottom: 0;
}
.promo_table tbody tr td:nth-child(4),
.promo_table tbody tr td:nth-child(6) {
  font-weight: 700;
  color: #00b5f0;
}
.promo_table tbody tr td:nth-child(2) {
  font-weight: 700;
}
.lluma_table_m.promo_table tbody tr td:first-child {
  border-bottom: 1px solid #d7d7d7;
}
.lluma_table_m.promo_table thead tr:first-child th {
  background-color: transparent;
  color: #000;
  font-size: 16px;
}
.lluma_table_m.promo_table tbody tr td:first-child {
  font-weight: 300;
}
.lluma_table_m.promo_table tbody tr td:nth-child(2) {
  font-weight: 700;
  color: #00b5f0;
}

/* information */
.info_icons {
  margin-top: 70px;
  padding-left: 0;
}
.info_icon {
  text-align: left;
}
.info_icon img {
  width: 50px;
}
.info_icon .icon_title {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 12px;
  padding-left: 0 !important;
}
.info_icon .icon_sub {
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
  padding-left: 0 !important;
}
.d-xl-none .info_icon {
  margin: 0 auto 30px;
  padding-left: 90px;
  position: relative;
  width: 330px;
}
.d-xl-none .info_icon img {
  position: absolute;
  top: 0;
  left: 0;
}
.d-xl-none .info_icon p {
  text-align: left;
}
.info_tab_container .nav-tabs {
  border-bottom: 0;
}
.info_tab_container .nav-tabs .nav-item {
  text-align: center;
}
.info_tab_container .nav-tabs .nav-link {
  border: 0;
  border-bottom: 5px solid #fff;
  padding: 0 0 5px;
  display: inline-block;
  font-size: 25px;
  font-weight: 700;
}
.info_tab_container .nav-tabs .nav-item.show .nav-link,
.info_tab_container .nav-tabs .nav-link.active {
  border-bottom-color: #ff0000;
}
.info_tab_container .tab-content {
  max-width: 1200px;
  margin: 80px auto 0;
}
.info_tab_container .tab-pane {
  padding: 0 15px;
}
.info_tab_container .tab-pane > .row {
  margin-bottom: 95px;
}
.info_tab_container .tab-pane > .row:last-child {
  margin-bottom: 0;
  padding: 0 0 100px;
}
.info_tab_container .tab_title {
  font-size: 25px;
  font-weight: 700;
  line-height: 32px;
}
.info_tab_container .tab_sub {
  margin-top: 15px;
  font-size: 14px;
  font-weight: 300;
}
.info_table tbody tr td {
  border-bottom: 1px solid #d7d7d7;
}
.info_table tbody tr:last-child td {
  border-bottom: 0;
}
.lluma_table_more {
  margin-top: 15px;
}
.lluma_table_more p {
  font-size: 12px;
  font-weight: 300;
  line-height: 18px;
  margin-bottom: 8px;
  word-break: keep-all;
}
.lluma_table_more p:last-child {
  margin-bottom: 0;
}
.lluma_table_more p::before {
  content: '*';
  color: #ff0000;
  font-size: 22px;
  vertical-align: middle;
  padding-right: 5px;
}
.info_case_title {
  font-size: 25px;
  font-weight: 700;
  margin-bottom: 45px;
}
.info_case_sub {
  margin-top: 30px;
  font-size: 15px;
  font-weight: 300;
}
.product_table thead tr th {
  background: transparent;
  color: #000;
  border-bottom: 1px solid #000;
}
.product_table thead tr th:first-child {
  background: #000 !important;
  color: #fff !important;
}
.product_table tbody tr td {
  border-bottom: 1px solid #d7d7d7;
}
.product_table tbody tr:last-child td {
  border-bottom: 0;
}

.area_width_control {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  flex-grow: 0;
  flex-shrink: 0;
  width: 100%;
}
.package_img {
  width: 304px;
}

/* quote */
.quote_title {
  font-size: 25px;
  font-weight: 700;
  margin-top: 50px;
}
.quote_title_img {
  max-width: 100%;
}
.quote_container {
  margin: 30px auto 60px;
}
.quote_select {
  position: relative;
  display: block;
  margin: 0 auto;
  width: 100%;
  font-size: 14px;
  text-align: left;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
}
.quote_select .quote_select_title {
  position: relative;
  display: block;
  background: #fff;
  border-bottom: 2px solid #000;
  color: #000;
  z-index: 1;
  padding: 1em;
  cursor: pointer;
  text-align: center;
}
.quote_bottom_border {
  border-bottom-color: #fff !important;
}
.quote_select .quote_select_title:hover {
  background: #fafafa;
}
.quote_select .quote_select_title:after {
  position: absolute;
  font-family: 'Font Awesome 5 Free';
  content: '\f107';
  right: 1em;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 10;
  transition: 0.2s;
}
.quote_select.is-open .quote_select_title {
  border-bottom-color: #fff;
}
.quote_container .quote_select.is-open .quote_select_title {
  border-bottom-color: #000;
}
.quote_select.is-open .quote_select_title:after {
  content: '\f106';
}
.quote_select.is-open ul {
  display: block;
}
.quote_select ul {
  display: none;
  position: absolute;
  overflow: hidden;
  overflow-y: auto;
  width: 100%;
  background: #fff;
  top: 100%;
  left: 0;
  list-style: none;
  padding: 0;
  z-index: 100;
  border: 1px solid #aaa;
  animation: slide-up 0.4s ease;
  -webkit-animation: slide-up 0.4s ease;
}
.quote_select ul li {
  display: block;
  text-align: left;
  padding: 0.8em 1em 0.8em 1em;
  /* color: #999; */
  cursor: pointer;
}
.quote_select ul li:hover {
  background: #ff0000;
  color: #fff;
}

.select_modal_content {
  min-height: 330px;
  border-radius: 0;
  padding: 40px 30px;
}
.select_modal_content .modal-body {
  padding: 0;
  text-align: center;
}
.select_modal_content .title {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 35px;
}
.select_modal_content .content {
  max-height: 600px;
  overflow-y: scroll;
  padding: 0 10px;
}
.select_modal_content input[type='radio'] {
  display: none;
}
.select_modal_content label {
  float: left;
  width: 50%;
  background: #f3f3f3;
  border: 2px solid #fff;
  padding: 1em;
  cursor: pointer;
}
.select_modal_content label:hover,
.select_modal_content label:focus,
.select_modal_content input[type='radio']:checked + label {
  background: #ff0000;
  color: #fff;
}
.select_modal_content .modal-footer {
  padding: 0;
  border-top: 0;
}
.select_modal_content .modal-footer .btn-block + .btn-block {
  margin-top: 0;
}
.modal_close {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 5;
  opacity: 0.4;
}

.with-border {
  border-top: 1px solid #d7d7d7;
  border-bottom: 1px solid #d7d7d7;
}

/* shopes */
.shop_container {
  margin: 20px auto 50px;
}
.shop_map #wrap {
  width: 100%;
  min-height: 280px;
  margin-top: -50px;
}
.shop_map #wrap #map {
  min-height: 280px;
}
.shop_search input {
  width: 100%;
  padding-left: 60px;
  font-size: 15px;
  font-weight: 300;
}
.shop_search input + img {
  position: absolute;
  top: 13px;
  left: 30px;
}
.shop_search .clear_button {
  background-color: rgba(255, 255, 255, 0.5);
  position: absolute;
  display: inline-block;
  top: 16px;
  right: 110px;
  cursor: pointer;
}
.shop_search .search_bar {
  height: 54px;
  font-family: 'Droid Sans', 'Droid Serif';
}
.shop_search .search_button {
  position: absolute;
  display: inline-block;
  height: 54px;
  width: 80px;
  right: 16px;
  background-color: #00b5f0;
  cursor: pointer;
  color: #ffffff;
}
.loading_bar_background {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 999999;
  top: 0;
}

.loading_bar_container_desktop {
  top: 20%; 
  bottom: 0;
  right: 0;
  left: 0;
  position: fixed;
  position: sticky;
  text-align: center;
  overflow: hidden;
}

.loading_bar_desktop {
  width: 40%;
  margin: 0 30% 0px 30%;
  height: 22%;
  object-fit: contain;
}

.loading_bar_text_desktop {
  text-align: center;
  font-size: 20px;
  color: #ffffff;
  width: auto;
}

.loading_bar_container_mobile {
  position: sticky;
  top: 30%;
}

.loading_bar_mobile {
  width: 80%;
  margin: 0 10% 0px 10%;
  height: 15%;
  object-fit: contain;
}

.loading_bar_text_mobile {
  text-align: center;
  font-size: 15px;
  color: #ffffff;
  width: auto;
}

.search_this_area_button {
  position: absolute;
  margin: 20px 0px 0px 0px;
  left: 42%;
  height: 45px;
  width: 150px;
  z-index: 100;
  background-color: #fff;
  color: #00b5f0;
  border-radius: 20px;
  border: 1px solid #00b5f0;
  cursor: pointer;
  font-size: 16px;
}
.search_this_area_button:hover {
  background-color: #00b5f0;
  border-color: #0096c7;
  color: #fff;
}
.shop_noresult {
  text-align: center;
  padding: 70px 0;
}
.shop_noresult img {
  margin: 50px 0;
  width: 200px;
}
.shop_noresult .shop_noresult_title {
  margin-top: 50px;
  font-weight: 700;
  font-size: 18px;
  color: #757575;
}
.shop_noresult .shop_noresult_text {
  margin-top: 20px;
  font-weight: 300;
  font-size: 14px;
  color: #aeaeae;
}
.shop_noresult .noresult_button {
  margin-top: 40px;
  width: 170px;
}
.num_of_shops {
  margin-top: 20px;
}
.shop_result_unit {
  /* padding: 15px 0; */
  position: relative;
}
.shop_result_unit .row {
  flex-wrap: nowrap;
}
.shop_result_unit .col-12 {
  flex: 0 0 0% !important;
}
.shop_result_unit .selected_bar {
  width: 3px;
  height: 170px;
  margin-left: -20px;
  margin-right: 20px;
  background-color: #ff0000;
}
.shop_result_unit p {
  /* padding-left: 35px; */
  font-size: 15px;
  font-weight: 300;
  line-height: 28px;
}
.shop_result_unit p:last-child {
  margin-bottom: 10px;
}
.shop_result_unit p.store {
  margin-top: 10px;
  font-size: 20px;
  font-weight: 700;
  line-height: 36px;
  cursor: pointer;
}
.shop_result_unit p.address {
  font-weight: 500;
  min-width: 190px;
}
.shop_result_unit .contact_kakao i {
  display: inline-block;
  margin-left: 5px;
  font-size: 20px;
  vertical-align: text-bottom;
  transition: 0.2s;
}
.shop_result_unit .contact_kakao:hover i,
.shop_result_unit .contact_kakao:focus i {
  transform: translateX(5px);
}
.shop_result_unit .btn-block {
  position: relative;
  width: 48%;
  margin: 0px 0px 10px 0px;
}
.shop_result_unit .btn-block + .btn-block {
  margin-top: 0;
}

.image_modal_content {
  min-height: 360px;
  border-radius: 0;
  padding: 20px;
}
.image_modal_content img {
  width: 100%;
}
.image_modal_content .modal-body {
  padding: 20px 0 0;
  text-align: center;
}
.image_modal_content .apply_title {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 35px;
  text-align: center;
}
.apply_title {
  word-break: keep-all;
}
.image_modal_content .modal-footer {
  border: 0px;
}
.image_modal_content button {
  margin: 0 auto;
}

.after_quote_apply_modal_content {
  min-height: 360px;
  max-width: 400px;
  border-radius: 0;
  padding: 0 0 20px;
}
.after_quote_apply_modal_content img {
  margin: 30px 0 30px;
  width: 110px;
}
.after_quote_apply_modal_content .modal-body {
  padding: 20px 0 0;
  text-align: center;
}
.after_quote_apply_modal_content .apply_title {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 35px;
  text-align: center;
}

.after_quote_apply_modal_content .modal-body-coupon {
  padding: 20px 0 0;
  text-align: center;
  position: relative;
}
.after_quote_apply_modal_content .coupon-section {
  margin: 0 auto;
  margin-bottom: 35px;
  width: 300px;
  height: 246px;
  text-align: center;
  position: relative;
}
.after_quote_apply_modal_content .coupon-image {
  width: 300px;
  height: 246px;
  text-align: center;
  position: relative;
}
.after_quote_apply_modal_content .coupon_title {
  width: 100%;
  margin: 42px 0px 0px 0px;
  position: absolute;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 35px;
  color: #ffffff;
  text-align: center;
  z-index: 2;
}

.after_quote_apply_modal_content .coupon_message {
  width: 100%;
  margin: 110px 0px 0px 0px;
  position: absolute;
  font-size: 15px;
  font-weight: 300;
  margin-bottom: 35px;
  color: #333333;
  text-align: center;
  z-index: 2;
}

.after_quote_apply_modal_content .coupon_code {
  width: 100%;
  margin: 205px 0px 0px 0px;
  position: absolute;
  font-size: 18px;
  font-weight: 500;
  color: #000000;
  letter-spacing: 3px;
  margin-bottom: 35px;
  text-align: center;
  z-index: 2;
}

.after_quote_apply_modal_content .modal-footer {
  border: 0px;
  justify-content: flex-start;
}
.after_quote_apply_modal_content button {
  margin: 0 auto;
}

.quote_apply_modal_content {
  min-height: 380px;
  border-radius: 0;
  padding: 50px;
}
.quote_apply_modal_content .modal-body {
  padding: 20px 0 0;
  text-align: left;
}
.quote_apply_modal_content .apply_title {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 55px;
  text-align: center;
}
.modal_content_transparent {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: transparent;
  background-clip: padding-box;
  outline: 0;
}

.quote_apply_modal_content .modal-body input[type='text'],
.quote_apply_modal_content .modal-body input[type='tel'],
.quote_apply_modal_content .modal-body input[type='email'],
.quote_apply_modal_content .modal-body input[type='number'] {
  max-width: 300px;
  width: 100%;
  font-size: 15px;
  letter-spacing: 1px;
  text-align: center;
}
#quote_apply_alarm {
  position: absolute;
  opacity: 0;
}
#quote_apply_alarm + label {
  position: relative;
  cursor: pointer;
  font-size: 14px;
  font-weight: 300;
  color: #666;
}
#quote_apply_alarm + label:before {
  content: '';
  margin-right: 5px;
  display: inline-block;
  vertical-align: text-top;
  width: 20px;
  height: 20px;
  background: #eee;
}
#quote_apply_alarm:checked + label:before {
  background: #ff0000;
}
#quote_apply_alarm:disabled + label {
  color: #555;
  cursor: auto;
}
#quote_apply_alarm:disabled + label:before {
  box-shadow: none;
  background: #ddd;
}
#quote_apply_alarm + label:after {
  content: '';
  position: absolute;
  left: 5px;
  top: 9px;
  background: white;
  width: 2px;
  height: 2px;
  box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white, 4px -6px 0 white, 4px -8px 0 white;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.quote_apply_modal_content .modal-footer {
  padding: 0;
  border-top: 0;
}
.quote_apply_modal_content .modal-footer .btn-block + .btn-block {
  margin-top: 0;
}

/* tint */
.tint_container {
  margin: 90px auto 60px;
}
.tint_title {
  font-size: 25px;
  font-weight: 700;
  margin-bottom: 30px;
  text-align: center;
}
.tint_select_area {
  position: relative;
  max-width: 429px;
  height: auto;
  width: 100%;
  margin: 0 auto;
}
.tint_select_area svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
}
.tint_select_area .tint_svg_window path {
  stroke: #00b5f0;
  stroke-width: 1px;
  fill: #fff;
  cursor: pointer;
}
.tint_select_area .tint_svg_window path.flicker {
  animation: window_flicker 1s ease-in infinite alternate;
  fill: #fff;
}
@keyframes window_flicker {
  0% {
    fill: #fff;
  }
  100% {
    fill: rgba(255, 0, 0, 0.15);
  }
}
.tint_select_area .tint_svg_window path:hover,
.tint_select_area .tint_svg_window path:focus {
  fill: rgba(0, 0, 0, 0.05);
}
.tint_select_area .tint_svg_window path.active {
  fill: #00b5f0;
}
.tint_select_area .tint_svg_window text.active {
  fill: #fff;
}
.tint_select_area .tint_svg_window path.flicker.active {
  fill: #00b5f0 !important;
  color: #fff !important;
  animation: none;
}
.tint_svg_map {
  width: 100%;
  height: auto;
}
.tint_select_area img {
  position: relative;
  width: 100%;
}
.tint_select_area img.top {
  z-index: 2;
}
.tint_select_area img.bottom {
  z-index: 1;
}
.tint_select_btn {
  margin: 60px auto;
  max-width: 429px;
  width: 100%;
}
.tint_select_btn .btn {
  float: left;
  width: 50%;
}
.tint_select_table_wrap {
  height: 460px;
}
.tint_select_table {
  margin: 0 auto;
  max-width: 500px;
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 8px;
}
.tint_select_table tr {
  display: none;
}
.tint_select_table tr.show {
  display: table-row;
}
.tint_select_table tr td {
  font-size: 16px;
  text-align: center;
  vertical-align: middle;
}
.tint_select_table tr td:first-child {
  background: #eee;
  font-weight: 500;
}
.tint_select_table tr td:nth-child(2) {
  padding-right: 10px;
}
.tint_select_table tr td:last-child img {
  opacity: 0.7;
}
.tint_select_table .quote_select {
  font-size: 16px;
}
.tint_select_table .quote_select .quote_select_title {
  border: 1px solid #aaa;
}
.tint_select_table .quote_select ul {
  border-top: 0;
  max-height: 170px;
  z-index: 102;
}
.tint_select_table .quote_select ul li {
  padding: 0.5em 1em;
  font-size: 14px;
}
.tint_sum {
  position: relative;
  float: left;
  width: 60%;
  text-align: right;
  padding-right: 20px;
  font-size: 30px;
  font-weight: 700;
  line-height: 50px;
}
.tint_sum + .btn {
  width: 40%;
}
.tint_guide_btn.btn {
  position: absolute;
  top: -75px;
  right: 25px;
  font-size: 16px;
  padding: 0 1rem;
  border-radius: 20px;
  border: 1px solid #000;
  line-height: 40px;
}
#tint_guide .modal-dialog {
  max-width: fit-content;
  width: fit-content;
}
#tint_guide .modal-body img {
  display: block;
  max-width: 550px;
  width: 100%;
  margin: 20px 0;
}
.tint_film_guide_btn.btn {
  position: absolute;
  top: -75px;
  right: 180px;
  font-size: 16px;
  padding: 0 1rem;
  border-radius: 20px;
  border: 1px solid #000;
  line-height: 40px;
}
.tint_sum_fixed {
  display: none;
  position: fixed;
  width: 100%;
  bottom: 0px;
  left: 0px;
  padding: 10px 15px 10px 0;
  background: #fff;
  z-index: 101;
  box-shadow: 0 -0.125rem 0.5rem rgba(0, 0, 0, 0.05) !important;
}
.tint_sum_fixed.show {
  display: block;
}

#product_guide .modal-body {
  padding-top: 50px;
}
#product_guide .modal-body img {
  display: inline-block;
  margin: 0px;
  width: 100%;
}

/* budget */
.budget_container {
  margin: 90px auto 60px;
  text-align: center;
}

.budget_title {
  text-align: center;
  font-size: 25px;
  font-weight: 700;
  margin-top: 60px;
}

.budget_component {
  width: 550px;
  height: 100px;
  margin: 0 auto;
  margin-top: 100px;
  display: inline-flex;
  align-items: center;
  justify-content: space-around;
}

.budget_price_box {
  height: 75px;
  width: 258px;
  margin-right: 20px;
  line-height: 75px;
  display: inline-flex;
  justify-content: center;
  background-color: #efefef;
}

.budget_price_text {
  font-size: 18px;
}

.budget_price_value {
  font-size: 26px;
}

.budget_price_input {
  width: 45px;
  height: 75px;
  margin: 0px 10px;
  padding: 0px !important;
  font-size: 26px;
  background-color: #efefef;
  border: 0px !important;
  text-align: right;
}

.budget_range_bar {
  width: 530px;
  height: 100px;
  margin: 0 auto;
  margin-top: 150px;
}

.budget_caption_box {
  width: 550px;
  position: relative;
  margin: 0 auto;
  margin-top: -80px;
  display: flex;
  justify-content: space-between;
}

.budget_price_caption {
  font-size: 18px;
}

/* React Slider */

.horizontal-slider .track-0 {
  height: 7px;
  background-color: #00b5f0;
  border-radius: 999px;
}

.horizontal-slider .track-1 {
  height: 7px;
  background-color: #e1e1e1;
  border-radius: 999px;
}

.horizontal-slider .thumb {
  height: 30px;
  width: 30px;
  font-size: 0px;
  background-color: #00b5f0;
  text-align: center;
  cursor: grab !important;
  border-radius: 50px;
  margin-top: -12px;
  border: 7px solid rgba(255, 255, 255);
  box-shadow: 0px 2px 4px #00b5f0;
}

.horizontal-slider .thumb:hover {
  height: 60px;
  width: 60px;
  font-size: 20px;
  color: #ffffff;
  line-height: 60px !important;
  background-color: #00b5f0;
  text-align: center;
  cursor: grab !important;
  border-radius: 50px;
  margin-top: -74px;
  margin-left: -15px;
  border: 0px;
  box-shadow: 0px;
}
.horizontal-slider .thumb:hover:before {
  content: '';
  display: block;
  position: absolute;
  height: 30px;
  width: 30px;
  font-size: 0px;
  background-color: #00b5f0;
  text-align: center;
  cursor: grab !important;
  border-radius: 50px;
  margin-top: 62px;
  margin-left: 15px;
  border: 7px solid rgba(255, 255, 255);
  box-shadow: 0px 2px 4px #00b5f0;
}
.horizontal-slider .thumb:hover:after {
  content: '';
  display: block;
  position: relative;
  top: -17px;
  left: 2px;
  bottom: 0px;
  border-color: #00b5f0 transparent transparent transparent;
  border-style: solid;
  border-width: 30px 28px 0px 28px;
  width: 0;
  height: 0;
}

/* footer */

.footer_container {
  padding: 0px;
  width: 100%;
  position: absolute;
  bottom: 0px;
  height: 217px;
}
footer {
  position: relative;
  bottom: 0;
  text-align: center;
  width: 100%;
  background: #001520;
  padding: 30px 0 10px;
  font-size: 14px;
}
.footer_title {
  list-style-type: none;
  opacity: 0.4;
}
.footer_group_container {
  margin: 0 auto;
}
.footer_group {
  position: relative;
  display: inline-flex;
  text-align: left;
  margin: 10px 50px 0;
}
.footer_group .footer_list {
  list-style-type: none;
}
.footer_copyright {
  font-size: 11px;
}

/*Terms & Policy*/
.terms_container {
  position: relative;
  display: block;
  text-align: center;
  max-width: 700px;
  margin: 0 auto;
}
.nav_container {
  padding: 0 0 50px;
  /*
  position: absolute;
  display: block;
  float: left;
   */
}
.terms_contents_container {
  position: relative;
  display: inline-block;
  text-align: justify;
  margin: 10px 50px 0;
}
.terms_title {
  font-size: 20px;
  font-weight: 600;
  color: #000000;
  padding: 0 0 10px;
  border-bottom: 1px solid #e9ecef;
}
.terms_date {
  font-size: 13px;
  font-weight: 300;
}
.terms_subtitle {
  font-size: 16px;
  font-weight: 700;
  text-align: left;
  color: #000000;
  margin: 0 10px;
}
.terms_contents {
  font-size: 14px;
  color: #000000;
  text-align: left;
  font-weight: 300;
  letter-spacing: -0.1px;
  line-height: 1.67;
  word-break: break-word;
  white-space: pre-wrap;
}
.policy_table {
  font-size: 14px;
  text-align: left;
  font-weight: 300;
  width: 100%;
  padding: 0;
  border: 1px solid #d7d7d7;
  border-left: 0;
  border-bottom: 0;
  border-collapse: separate;
}
.policy_table tr th {
  border-left: 1px solid #d7d7d7;
  border-bottom: 1px solid #d7d7d7;
  padding: 10px 10px;
}
.policy_table tr td {
  border-left: 1px solid #d7d7d7;
  border-bottom: 1px solid #d7d7d7;
  padding: 10px 10px;
}

/* local shortcut button */
.btn_group {
  width: 100%;
  margin: 0px;
  flex-wrap: wrap;
  border-top: 1px solid #e6e7ee;
  border-left: 1px solid #e6e7ee;
}

.btn_group_m {
  width: 100%;
  flex-wrap: wrap;
  margin: 0px 0px 0px -1px;
  border-top: 0px;
  border-left: 1px solid #e6e7ee;
  animation: slide-up 0.4s ease;
  -webkit-animation: slide-up 0.4s ease;
}

.btn_group button {
  background-color: #fff;
  color: rgba(0, 0, 0, 0.75);
  cursor: pointer;
  width: 20%;
  height: 44px;
  font-size: 16px;
  box-sizing: border-box;
  border-bottom: 1px solid #e6e7ee;
  border-right: 1px solid #e6e7ee;
}

.btn_group_m button {
  background-color: #fff;
  color: rgba(0, 0, 0, 0.75);
  cursor: pointer;
  width: 33.3%;
  height: 58px;
  font-size: 14px;
  border-bottom: 1px solid #e6e7ee;
  border-right: 1px solid #e6e7ee;
}

.arrow_down {
  position: absolute;
  width: 15px;
  height: 15px;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 14px solid #000;
  background-color: #fff;
  left: 83%;
  margin: 5px 0px 0px 0px;
}

.selected_local_box {
  height: 54px;
  width: 100%;
  border: 1px solid #e6e7ee;
  background-color: #fff;
  margin: 20px 0px 0px -1px;
  padding: 15px 0px 0px 20px;
}

.selected_button {
  background-color: #000 !important;
  color: #fff !important;
}

.btn_group:after {
  content: '';
  clear: both;
  display: table;
}

.btn_group button:hover {
  background-color: #545454;
  color: #fff;
}

/* 반응형 */
/* Extra Small */
@media (max-width: 575px) {
  .hidden-xs {
    display: none !important;
  }
  .tint_select_area {
    transform: scale(0.65);
    transform-origin: left top;
    width: 429px !important;
    margin-bottom: -120px;
    left: 50%;
    margin-left: -139.4px;
  }

  .budget_title {
    text-align: center;
    font-size: 14px;
    line-height: 200%;
    font-weight: 700;
    margin-top: 100px;
  }

  .budget_component {
    width: 250px;
    height: 120px;
    margin-top: 50px;
    text-align: center;
    display: inline-block;
    align-items: center;
  }

  .budget_price_box {
    height: 50px;
    width: 220px;
    margin-right: 0px;
    margin-bottom: 20px;
    line-height: 50px;
    display: inline-flex;
    justify-content: center;
    background-color: #efefef;
  }

  .budget_price_text {
    font-size: 15px;
  }

  .budget_price_value {
    font-size: 20px;
  }

  .budget_price_input {
    width: 40px;
    height: 50px;
    margin: 0px 10px;
    padding: 0px !important;
    font-size: 20px;
    background-color: #efefef;
    border: 0px !important;
    text-align: right;
  }

  .budget_range_bar {
    width: 250px;
    height: 100px;
    margin: 0 auto;
    margin-top: 100px;
  }

  .budget_slider_track {
    height: 10px;
    width: 100%;
    background-color: #ccc;
  }

  .budget_caption_box {
    width: 300px;
    position: relative;
    margin: 0 auto;
    margin-top: -80px;
    display: flex;
    justify-content: space-between;
  }

  .budget_price_caption {
    font-size: 16px;
  }

  .btn_lg {
    font-size: 16px;
  }
}
/* Small - sm */
@media (min-width: 576px) {
  .hidden-sm {
    display: none !important;
  }
  .container {
    max-width: 768px;
  }
  #quote_apply_modal .modal-dialog {
    max-width: 400px;
  }
  #after_quote_apply_modal .modal-dialog {
    max-width: 400px;
  }
  #not_support_yet .modal-dialog {
    max-width: 400px;
  }
}

/* xs,sm 에만 적용되는 스타일 */
@media (max-width: 767px) {
  .hidden-sm-down {
    display: none !important;
  }
  .mt-150 {
    margin-top: 50px !important;
  }
  .my-150 {
    margin-top: 50px !important;
    margin-bottom: 50px !important;
  }
  .banner_container p,
  .info_tab_container .d-table-cell p {
    padding-left: 0;
    text-align: center;
  }
  .home_prom_back::before {
    content: '';
    position: absolute;
    width: 50%;
    height: 200px;
    background-color: #ff0000;
  }
  .home_prom_back .banner_container .col-12 {
    min-height: 250px;
  }
  .home_prom_back .banner_container .col-12:first-child img {
    position: absolute;
    width: 80% !important;
    bottom: -50px;
    right: 0;
  }
  .absolute_headline {
    position: relative;
    display: inline-block;
    margin: 150px auto 0;
    padding: 30px 25px;
    background-color: rgba(255, 255, 255, 0.8) !important;
  }
  .absolute_headline p.headline {
    font-size: 30px;
  }
  .banner_slide_indicators {
    bottom: -30px;
    margin: 0;
    /*display: none;*/
  }
  .banner_slide_indicators li:nth-child(4) {
    flex: 1;
    -ms-flex: 1;
    text-align: center;
    text-indent: 0;
    background-color: transparent;
    height: fit-content;
    font-size: 12px;
    display: none;
    visibility: hidden;
  }
  .promo_why {
    margin-top: 40px;
  }
  .promo_why p {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    padding: 0;
  }
  .promo_why img {
    margin-bottom: 20px;
  }
  .info_icon .icon_sub {
    padding-left: 0 !important;
  }
  .info_tab_container {
    padding: 0 !important;
  }
  .info_tab_container .nav-tabs .nav-link {
    font-size: 21px;
  }
  .info_tab_container .nav-tabs .nav-item {
    padding-left: 10px;
    padding-right: 10px;
  }
  .info_tab_container .tab-content {
    margin-top: 40px;
  }
  .info_tab_container .tab-pane > .row {
    margin-bottom: 50px;
  }
  .info_tab_container .tab-pane > .row.product_table_row {
    margin-bottom: 0 !important;
  }
  .info_tab_container .d-table-cell .tab_sub {
    padding: 0 35px;
  }
  .info_tab_container .tab-pane .lluma_table_m {
    min-width: 90% !important;
  }
  .lluma_table_m.info_table tr th:first-child,
  .lluma_table_m.info_table tr td:first-child {
    text-align: center !important;
  }
  .info_tab_container .tab-pane .lluma_table_m.d-xl-none {
    min-width: 100% !important;
  }
  .info_tab_container .tab-pane .lluma_table_m.d-xl-none tr th {
    border-bottom: 1px solid #d7d7d7;
    vertical-align: middle;
  }
  .info_tab_container .tab-pane .lluma_table_m.d-xl-none tr:last-child th {
    border-bottom: 0;
  }
  .info_case_title {
    font-size: 22px;
    margin-bottom: 25px;
  }
  .info_case_sub {
    margin-top: 20px;
    font-size: 14px;
  }

  .quote_title {
    font-size: 20px;
    margin-top: 30px;
  }
  .quote_apply_modal_content {
    padding: 40px 30px;
  }
  .select_modal_dialog {
    width: 100%;
    height: 100%;
    margin: 0;
  }
  .select_modal_content {
    height: 100%;
    padding: 40px 15px;
  }
  .select_modal_content .modal-body {
    height: 100%;
  }
  .select_modal_content .content {
    max-height: 90%;
  }
  .select_modal_content label {
    font-size: 14px;
  }
  .shop_result_unit .consulting_button {
    width: 48%;
  }
  .tint_title {
    font-size: 20px;
    margin-top: 70px;
  }
  #tint_guide .modal-dialog {
    margin: 0 auto;
  }
  #tint_guide .modal-content {
    border-radius: 0;
  }
  #film_info .modal-dialog {
    margin: 0 auto;
  }
  #film_info .modal-content {
    border-radius: 0;
  }
  .footer_group_container {
    margin: 0 auto;
    text-align: left;
  }
}

/* xs,sm,md 에만 적용되는 스타일 */
@media (max-width: 991px) {
  .shop_container .representative_images img {
    height: 100px;
    width: 100px;
  }
  .tint_select_btn {
    margin: 20px auto 60px;
  }
  .tint_select_table_wrap {
    height: fit-content;
  }
  .tint_select_table tr td {
    font-size: 14px;
  }
  .tint_select_table tr td:first-child {
    width: 80px;
  }
  .tint_select_table tr td:last-child {
    width: 40px;
  }
  .tint_select_table tr td:last-child img {
    width: 15px;
  }
  .tint_select_table .quote_select .quote_select_title {
    font-size: 14px;
  }
  .tint_sum {
    font-size: 22px;
  }
  .banner_slide_indicators li > div {
    display: inline-block;
    margin-bottom: -10px;
    padding-bottom: 4px;
    border-bottom: 5px solid transparent;
  }
  .banner_container .headline.font-en {
    margin-top: 30px;
  }
  .info_tab_container .tab-pane {
    padding: 0 30px;
  }
  .terms_container .info_tab_container .nav-tabs .nav-link {
    font-size: 15px;
  }
  .footer_group_container {
    margin: 0 auto;
  }
  .shop_result_unit p.store {
    font-size: 17px;
    font-weight: 700;
    line-height: 36px;
    cursor: pointer;
  }
  .shop_result_unit p.address {
    width: 190px !important;
    font-weight: 500;
  }
}

/* Medium - md */
@media (min-width: 768px) {
  .hidden-md-up {
    display: none !important;
  }
  #product_guide .modal-dialog {
    width: 700px;
    max-width: 700px;
  }
  .container {
    max-width: 992px;
  }
  header .nav_link {
    display: inline-block;
    margin: 0 30px;
  }

  .banner_container .row .col-md-6,
  .info_tab_container .row .col-md-6 {
    margin-bottom: 0;
  }

  .select_modal_dialog {
    max-width: 500px;
  }
  .quote_btn {
    padding-left: 16%;
    text-align: left !important;
  }
}
/* Large - lg */
@media (min-width: 992px) {
  #product_guide .modal-dialog {
    width: 900px;
    max-width: 900px;
  }
  .shop_container .representative_images img {
    height: 170px;
    width: 170px;
  }
  .container {
    max-width: 1200px;
  }
  .btn {
    font-size: 20px;
  }
  .btn_sm {
    font-size: 15px;
  }
  .btn_lg {
    font-size: 23px;
  }
  .banner_container .headline.font-en {
    font-size: 70px;
    line-height: 86px;
    font-weight: 700;
    margin-top: 30px;
  }
  .banner_container .headline {
    font-size: 60px;
    line-height: 75px;
    font-weight: 700;
    color: #00b5f0;
  }
  .banner_container .sub {
    margin-top: 20px;
    font-size: 15px;
    font-weight: 300;
  }
  .banner_container p,
  .info_icons,
  .info_tab_container .d-table-cell p {
    padding-left: 16%;
  }

  .budget_title {
    text-align: center;
    font-size: 30px;
    font-weight: 700;
    margin-top: 150px;
  }

  .budget_component {
    width: 750px;
    height: 120px;
    margin: 0 auto;
    margin-top: 150px;
    display: inline-flex;
    align-items: center;
    justify-content: space-around;
  }

  .budget_price_box {
    height: 75px;
    width: 258px;
    margin-right: 20px;
    line-height: 75px;
    display: inline-flex;
    justify-content: center;
    background-color: #efefef;
  }

  .budget_price_text {
    font-size: 18px;
  }

  .budget_price_value {
    font-size: 26px;
  }

  .budget_price_input {
    width: 45px;
    height: 75px;
    margin: 0px 10px;
    padding: 0px !important;
    font-size: 26px;
    background-color: #efefef;
    border: 0px !important;
    text-align: right;
  }

  .budget_range_bar {
    width: 730px;
    height: 100px;
    margin: 0 auto;
    margin-top: 150px;
  }

  .budget_caption_box {
    width: 750px;
    position: relative;
    margin: 0 auto;
    margin-top: -80px;
    display: flex;
    justify-content: space-between;
  }

  .budget_price_caption {
    font-size: 25px;
  }

  .find_shops_m {
    display: none;
    visibility: hidden;
  }
  .shop_container {
    margin: 50px auto;
  }
  .shop_search {
    margin-bottom: -54px;
  }
  .shop_result {
    padding-top: 54px;
  }
  .shop_map #wrap {
    width: 100%;
    min-height: 800px;
    margin-top: -50px;
  }
  .shop_map #wrap #map {
    min-height: 600px;
    margin-top: 90px;
  }
  .sides_container {
    padding-left: 0px !important;
    width: 570px !important;
    display: inline-block;
    text-align: center;
  }

  .shop_result_unit p:last-child {
    margin-bottom: 0;
  }
  .shop_result_unit .contact_kakao {
    margin-top: 20px;
  }
  .shop_result_unit .btn-block {
    position: relative;
    width: 48%;
    /* margin: 20px 20px 10px 0px; */
  }
  .shop_result_unit .btn-block + .btn-block {
    margin-top: 0.5rem;
  }
  .tint_select_table_wrap_6 {
    min-height: 460px;
    height: fit-content;
  }
}

@media (max-width: 1450px) {
  .package_button {
    margin-right: 28px;
    margin-top: -6px;
    margin-bottom: 28px;
    width: 304px;
    height: 65px;
    font-size: 20px;
    font-weight: 400;
    line-height: 60px;
  }
}
/* Extra Large - xl */
@media (min-width: 1200px) {
  .container {
    max-width: 1500px;
  }
  .btn {
    padding: 0 32px;
  }
  .btn_lg {
    font-size: 21px;
  }

  #product_guide .modal-dialog {
    width: 1100px;
    max-width: 1100px;
  }

  #tint_guide .modal-body img {
    display: inline-block;
    max-width: 550px;
    margin: 0;
  }
  .shop_map #wrap {
    width: 100%;
    margin-top: -50px;
  }

  .shop_map #wrap #map {
    min-height: 800px;
    margin-top: 90px;
  }
  .find_shops_m {
    display: none;
    visibility: hidden;
  }
}

/* bootstrap css 추가 */
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}
.bg-white {
  box-shadow: 0 -2px 14px -2px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
}
.bg-black {
  background-color: #000 !important;
}
a.bg-black:hover,
a.bg-black:focus,
button.bg-black:hover,
button.bg-black:focus {
  background-color: #222 !important;
}
.bg-red {
  background-color: #ff0000 !important;
}
a.bg-red:hover,
a.bg-red:focus,
button.bg-red:hover,
button.bg-red:focus {
  background-color: #e50000 !important;
}
.text-black {
  color: #000 !important;
}
.text-gray {
  color: #939393 !important;
}
a.text-black:hover,
a.text-black:focus {
  color: #222 !important;
}
.text-red {
  color: #ff0000 !important;
}
a.text-red:hover,
a.text-red:focus {
  color: #e50000 !important;
}
.border-dark {
  border-color: #000 !important;
}
.text-underline {
  text-decoration: underline;
}
.font-weight-100 {
  font-weight: 100 !important;
}
.font-weight-300 {
  font-weight: 300 !important;
}
.font-weight-400 {
  font-weight: 400 !important;
}
.font-weight-500 {
  font-weight: 500 !important;
}
.font-weight-600 {
  font-weight: 600 !important;
}
.font-weight-700 {
  font-weight: 700 !important;
}
.font-weight-800 {
  font-weight: 800 !important;
}
.font-weight-200 {
  font-weight: 900 !important;
}
.font-en {
  font-family: 'Open Sans', sans-serif;
}
.mt-10 {
  margin-top: 10px;
}
.mt-30 {
  margin-top: 30px;
}
.mt-100 {
  margin-top: 100px;
}
.mt-150 {
  margin-top: 150px;
}
.my-150 {
  margin-top: 150px;
  margin-bottom: 150px;
}
.mb-10 {
  margin-bottom: 10px;
}
.mb-20 {
  margin-bottom: 20px;
}
.mb-50 {
  margin-bottom: 50px;
}
.mb-150 {
  margin-bottom: 150px;
}
.w-85 {
  width: 85%;
}
.w-90 {
  width: 90%;
}
.w-100 {
  height: 100% !important;
}
.text-indent {
  text-indent: 3mm;
}
.pl-10 {
  padding-left: 10px;
}
.a-link {
  color: dodgerblue;
}
.table {
  display: table;
  border-collapse: separate;
  border-spacing: 2px;
  border-color: gray;
}

/* SendBird SDK css */
#sb_widget .chat-section .chat-board .top .ic-invite {
  display: none;
}
#sb_widget .chat-section .chat-board .top .ic-leave {
  display: none;
}
#sb_widget .chat-section .chat-board .top .ic-members {
  display: none;
}
#sb_widget .chat-section .chat-board .top .ic-close {
  right: -10px;
}
#sb_widget .chat-section .chat-board {
  margin-top: 0px;
  margin-right: -10px;
  margin-bottom: 10px;
}
#sb_widget .chat-section .new-chat-btn {
  display: none !important;
}
#sb_widget .chat-section .chat-board .top .count {
  display: none;
}
#sb_widget .chat-section .chat-board .content .input .text {
  position: relative;
  bottom: -10px;
  padding: 0px 8px 0 0;
}
#sb_widget .chat-section .chat-board .content .input .file {
  display: none;
}

.shop_container .shop_store_information {
  display: flex;
  margin-top: 80px;
  margin-bottom: 30px;
  margin-left: 20px;
}

.shop_container .representative_images .img_section img {
  /* width: 100%; */
  object-fit: cover;
}

.representative_images_nav {
  position: relative;
  width: 100%;
  padding: 0px;
  margin: 0px;
}
.representative_images_nav img {
  width: 100%;
}

.modal_images_nav {
  height: 100%;
  position: absolute;
  width: 100%;
  top: 0px;
  padding: 20px 0px;
}
.modal_images_nav img {
  width: 100%;
}

.shop_result_unit_for_selected {
  padding: 20px 0;
  position: relative;
  border-bottom: 1px solid #dddddd;
}
.shop_result_unit_for_selected span {
  padding-left: 35px;
  font-size: 15px;
  font-weight: 300;
  line-height: 28px;
}
.shop_result_unit_for_selected span.store {
  font-size: 20px;
  font-weight: 700;
  line-height: 36px;
}

#map div[style*='z-index: 119'] {
  font-size: 14px !important;
  width: 200px !important;
  height: 40px !important;
  border: 1px solid #00b5f0 !important;
  padding: 0px !important;
  margin: 0px !important;
}
#map div[style*='z-index: 119'] > div:first-child {
  display: none !important;
}
.info_window {
  text-align: center !important;
  width: 200px !important;
  line-height: 38px !important;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* sweet alert2 */
@media (max-width: 1200px) {
  .swal2-popup.swal2-modal.swal2-show {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    padding: 10px;
    line-height: 0px !important;
    border-radius: 0%;
    width: 330px;
    height: 460px;
  }
  .swal2-header {
    display: initial !important;
    height: 40% !important;
  }
  .swal2-confirm.swal2-styled {
    font-size: 18px !important;
    text-align: center !important;
    display: block !important;
    width: 269px !important;
    height: 50px !important;
    background-color: #ff0000 !important;
    border: 1px solid #ff0000;
    border-radius: 0% !important;
  }
  #swal2-title.swal2-title {
    position: absolute;
    top: 10px;
    left: 10px;
    right: 10px;
    height: 70% !important;
    display: block !important;
    text-align: center !important;
    margin-bottom: 0px !important;
  }
  .swal2-title-text {
    color: #000000 !important;
    position: relative;
    text-align: center !important;
    line-height: 150% !important;
    font-weight: 700 !important;
    font-size: 18px !important;
  }
  #swal2-content {
    font-size: 10px !important;
    height: auto;
    display: block !important;
    position: absolute;
    right: 10px !important;
    left: 10px !important;
    width: auto;
    margin-top: 0px !important;
    text-align: center !important;
  }
  .swal2-images {
    display: block !important;
    width: 100%;
    height: 70% !important;
    margin-top: 0px !important;
    margin-bottom: 20px !important;
  }
  .swal2-cancel {
    width: 50%;
    display: block !important;
    text-decoration: underline;
    font-size: 9px !important;
    margin-bottom: 15px !important;
    color: #bababa !important;
    background-color: rgba(255, 255, 255, 0.5) !important;
  }
  .swal2-cancel:hover,
  .swal2-cancel :focus {
    background: initial !important;
  }
  .swal2-close {
    font-size: 45px !important;
    position: absolute;
    top: 6px !important;
  }
  .swal2-actions {
    position: absolute;
    height: 100px;
    width: auto !important;
    display: block;
    margin: initial !important;
    bottom: 10px !important;
    right: 10px;
    left: 10px;
  }
  .shop_result_unit {
    /* padding: 15px 0; */
    position: relative;
  }
  .shop_result_unit .row {
    flex-wrap: nowrap;
  }
}

@media (min-width: 1030px) {
  .swal2-popup.swal2-modal.swal2-show {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    padding: 10px;
    line-height: 0px !important;
    border-radius: 0%;
    width: 413px;
    height: 620px;
  }
  .swal2-header {
    display: none;
    height: 45% !important;
  }
  .swal2-confirm.swal2-styled {
    font-size: 18px !important;
    text-align: center !important;
    /* display: block !important; */
    width: 269px !important;
    height: 50px !important;
    background-color: #ff0000 !important;
    border: 1px solid #ff0000;
    border-radius: 0% !important;
  }
  #swal2-title.swal2-title {
    position: absolute;
    top: 10px;
    left: 10px;
    right: 10px;
    height: 70% !important;
    display: block !important;
    text-align: center !important;
    margin-bottom: 0px !important;
  }
  .swal2-title-text {
    color: #000000 !important;
    position: relative;
    text-align: center !important;
    line-height: 150% !important;
    font-weight: 700 !important;
    font-size: 26px !important;
  }
  #swal2-content {
    font-size: 15px !important;
    height: auto;
    display: block !important;
    position: relative;
    width: auto;
    margin-top: 0px !important;
    text-align: center !important;
  }
  .swal2-images {
    display: block !important;
    width: 100%;
    height: 70% !important;
    margin-top: 0px !important;
    margin-bottom: 20px !important;
  }
  .swal2-cancel {
    width: auto;
    display: block !important;
    text-decoration: underline;
    font-size: 15px !important;
    margin-bottom: 40px !important;
    color: #bababa !important;
    background-color: rgba(255, 255, 255, 0.5) !important;
  }
  .swal2-cancel:hover,
  .swal2-cancel :focus {
    background: initial !important;
  }
  .swal2-close {
    font-size: 45px !important;
    position: absolute;
    top: 6px !important;
  }
  .swal2-actions {
    position: absolute;
    height: 131px;
    width: auto !important;
    display: block;
    margin: initial !important;
    bottom: 0px !important;
    right: 10px;
    left: 10px;
  }
  .shop_result_unit {
    /* padding: 15px 0; */
    position: relative;
  }
  .shop_result_unit .row {
    flex-wrap: nowrap;
  }
  .shop_result_unit p {
    /* padding-left: 35px; */
    font-size: 15px;
    font-weight: 300;
    line-height: 28px;
  }
  .shop_result_unit p:last-child {
    margin-bottom: 10px;
  }
  .shop_result_unit p.store {
    margin-top: 10px;
    margin-left: 20px;
    font-size: 20px;
    font-weight: 700;
    line-height: 36px;
    cursor: pointer;
    min-width: 190px;
    width: 300px;
  }
  .shop_result_unit p.address {
    margin-left: 20px;
    font-weight: 500;
    min-width: 190px;
    width: 300px;
  }
}

@media (min-width: 1030px) and (max-width: 1250px) {
  .shop_result_unit p.store {
    width: 200px;
  }
  .shop_result_unit p.address {
    width: 200px;
  }
}

@media (max-width: 1024px) {
  .shop_result_unit p.store {
    margin-left: 20px;
    margin-top: 10px;
    font-size: 20px;
    font-weight: 700;
    line-height: 36px;
    cursor: pointer;
    max-width: 190px;
  }
  .shop_result_unit p.address {
    margin-left: 20px;
    font-weight: 500;
    max-width: 190px;
  }
  .shop_search .search_bar {
    width: 100%;
  }
}

@media (max-width: 991px) {
  .swal2-popup.swal2-modal.swal2-show {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    padding: 10px;
    line-height: 0px !important;
    border-radius: 0%;
    width: 330px;
    height: 460px;
  }
  .swal2-header {
    display: initial !important;
    height: 40% !important;
  }
  .swal2-confirm.swal2-styled {
    font-size: 18px !important;
    text-align: center !important;
    width: 269px !important;
    height: 50px !important;
    background-color: #ff0000 !important;
    border: 1px solid #ff0000;
    border-radius: 0% !important;
  }
  #swal2-title.swal2-title {
    position: absolute;
    top: 10px;
    left: 10px;
    right: 10px;
    height: 70% !important;
    display: block !important;
    /* display: none !important; */
    text-align: center !important;
    margin-bottom: 0px !important;
  }
  .swal2-title-text {
    color: #000000 !important;
    position: relative;
    text-align: center !important;
    line-height: 150% !important;
    font-weight: 700 !important;
    font-size: 18px !important;
  }
  #swal2-content {
    font-size: 10px !important;
    display: block !important;
    position: absolute;
    right: 10px !important;
    left: 10px !important;
    width: auto;
    margin-top: 0px !important;
    text-align: center !important;
  }
  .swal2-images {
    display: block !important;
    width: 100%;
    height: 70% !important;
    margin-top: 0px !important;
    margin-bottom: 20px !important;
  }
  .swal2-cancel {
    width: auto;
    text-decoration: underline;
    font-size: 9px !important;
    margin-bottom: 15px !important;
    color: #bababa !important;
    background-color: rgba(255, 255, 255, 0.5) !important;
  }
  .swal2-cancel:hover,
  .swal2-cancel :focus {
    background: initial !important;
  }
  .swal2-close {
    font-size: 30px !important;
    position: absolute;
    top: 6px !important;
  }
  .swal2-actions {
    position: absolute;
    height: 100px;
    width: auto !important;
    display: block;
    margin: initial !important;
    right: 10px;
    left: 10px;
  }
  .shop_result_unit p.store {
    margin-left: 30px !important;
    margin-top: 2px;
    font-size: 20px;
    font-weight: 700;
    line-height: 36px;
    cursor: pointer;
  }
  .shop_result_unit p.address {
    margin-left: 30px;
    font-weight: 500;
    width: 100% !important;
  }
  .shop_search .search_bar {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .shop_search .search_bar {
    width: 100%;
  }
}

/*mobile*/

@media (max-width: 575px) {
  .swal2-popup.swal2-modal.swal2-show {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    padding: 10px;
    line-height: 0px !important;
    border-radius: 0%;
    width: 330px;
    height: 460px;
  }
  .swal2-header {
    display: none;
    height: 40% !important;
  }
  .swal2-confirm.swal2-styled {
    font-size: 18px !important;
    text-align: center !important;
    width: 269px !important;
    height: 50px !important;
    background-color: #ff0000 !important;
    border: 1px solid #ff0000;
    border-radius: 0% !important;
  }
  #swal2-title.swal2-title {
    position: absolute;
    top: 10px;
    left: 10px;
    right: 10px;
    height: 70% !important;
    display: block !important;
    text-align: center !important;
    margin-bottom: 0px !important;
  }
  .swal2-title-text {
    color: #000000 !important;
    position: relative;
    text-align: center !important;
    line-height: 150% !important;
    font-weight: 700 !important;
    font-size: 18px !important;
  }
  .swal2-content {
    display: initial !important;
  }
  #swal2-content {
    font-size: 10px !important;
    height: auto;
    display: block !important;
    position: absolute;
    right: 10px !important;
    left: 10px !important;
    width: auto;
    margin-top: 0px !important;
    text-align: center !important;
  }
  .swal2-images {
    display: block !important;
    width: 100%;
    height: 70% !important;
    margin-top: 0px !important;
    margin-bottom: 20px !important;
  }
  .swal2-cancel {
    width: auto;
    text-decoration: underline;
    font-size: 9px !important;
    margin-bottom: 15px;
    color: #bababa !important;
    background-color: rgba(255, 255, 255, 0.5) !important;
  }
  .swal2-cancel:hover,
  .swal2-cancel :focus {
    background: initial !important;
  }
  .swal2-close {
    font-size: 30px !important;
    position: absolute;
    top: 6px !important;
  }
  .swal2-actions {
    position: absolute;
    height: 100px;
    width: auto !important;
    display: block;
    right: 10px;
    left: 10px;
  }
  /* Galaxy */
  #product_guide .modal-body img {
    display: inline-block;
    width: 100%;
    height: auto;
  }

  .tint_guide_button_pos {
    position: relative;
    top: 55px;
  }
  .btn_position_mobile {
    margin-left: 97px;
  }
  .shop_result_unit p.store {
    margin-top: 2px;
    font-size: 15px;
    font-weight: 700;
    line-height: 36px;
    cursor: pointer;
  }
  .shop_result_unit p.address {
    font-weight: 500;
    width: 165px !important;
  }
  .shop_result_unit .btn-block {
    position: relative;
    width: 45%;
    margin-left: -5px !important;
  }
  .package_area .row {
    margin-left: 40px;
  }
}

/* Pixel */
@media (max-width: 414px) {
  .tint_guide_button_pos {
    position: relative;
    right: 25px;
    top: 35px;
  }
  .btn_position_mobile {
    margin-left: 105px;
  }
  .shop_search .search_bar {
    width: 340px !important;
  }
  .shop_result_unit p.store {
    width: 150px !important;
    margin-left: 20px !important;
    margin-top: 0px;
    font-size: 15px;
    font-weight: bold;
    line-height: 36px;
    cursor: pointer;
  }
  .shop_result_unit p.address {
    margin-left: 20px;
    font-weight: 500;
    font-size: 14px;
    width: 200px !important;
  }
  .shop_result_unit .btn-block {
    position: relative;
    width: 48%;
    margin-left: -13px;
  }
  .shop_result_unit .consulting_button {
    margin: 0px 0px 10px 10px;
  }
  .shop_result_unit .col-12 {
    padding-right: 0px !important;
  }
  .area_width_control {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    flex-grow: 0;
    flex-shrink: 0;
    width: 100%;
  }
  .package_area {
    margin-top: 50px;
  }
  .package_area .row {
    margin-left: 0px;
    margin-right: 0px;
  }
  .package_img_mobile {
    width: 185px !important;
  }
  .package_btn_mobile {
    width: 185px;
    height: 50px;
    line-height: 3;
    margin-right: 0px;
    margin-left: 0px;
    font-size: 16px !important;
  }
  .shop_container .shop_store_information {
    display: flex;
    margin-top: 60px;
    margin-bottom: 30px;
    margin-left: 20px;
  }
}

@media (max-width: 375px) {
  .quote_title {
    font-size: 18px;
    margin-top: 30px;
  }
  .area_width_control {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    flex-grow: 0;
    flex-shrink: 0;
    width: 100%;
  }
  .package_area {
    margin-top: 50px;
  }
  .package_area .row {
    margin-left: 0px;
    margin-right: 0px;
  }
  .package_img_mobile {
    width: 165px !important;
  }
  .package_btn_mobile {
    width: 165px;
    margin-right: 0px;
    margin-left: 0px;
    font-size: 17px !important;
  }
  .shop_container .shop_store_information {
    display: flex;
    margin-top: 60px;
    margin-bottom: 30px;
    margin-left: 20px;
  }
}

@media (max-width: 360px) {
  .shop_search .search_bar {
    width: 300px !important;
  }
  .shop_result_unit p.store {
    width: 175px !important;
    margin-left: 20px !important;
    margin-top: -5px;
    font-size: 15px;
    font-weight: bold;
    line-height: 36px;
    cursor: pointer;
  }
  .shop_result_unit p.address {
    margin-left: 20px;
    font-weight: 500;
    font-size: 14px;
    width: 180px !important;
  }
  .shop_result_unit .consulting_button {
    margin: 0px 0px 10px 10px;
  }
  .area_width_control {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    flex-grow: 0;
    flex-shrink: 0;
    width: 100%;
  }
  .package_area {
    margin-top: 50px;
  }
  .package_area .row {
    margin-left: 0px;
    margin-right: 0px;
  }
  .package_img_mobile {
    width: 160px !important;
  }
  .package_btn_mobile {
    width: 160px;
    height: 50px;
    line-height: 3;
    margin-right: 0px;
    margin-left: 0px;
    font-size: 16px !important;
  }
  .shop_container .shop_store_information {
    display: flex;
    margin-top: 60px;
    margin-bottom: 30px;
    margin-left: 20px;
  }
}

/* iPhone 5/SE*/

@media (max-width: 330px) {
  .swal2-popup.swal2-modal.swal2-show {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    padding: 10px;
    line-height: 0px !important;
    border-radius: 0%;
    width: 330px;
    height: 460px;
  }
  .swal2-header {
    display: none;
    height: 40% !important;
  }
  .swal2-confirm.swal2-styled {
    font-size: 18px !important;
    text-align: center !important;
    width: 269px !important;
    height: 50px !important;
    background-color: #ff0000 !important;
    border: 1px solid #ff0000;
    border-radius: 0% !important;
  }
  #swal2-title.swal2-title {
    position: absolute;
    top: 10px;
    left: 10px;
    right: 10px;
    height: 70% !important;
    display: block !important;
    text-align: center !important;
    margin-bottom: 0px !important;
  }
  .swal2-title-text {
    color: #000000 !important;
    position: relative;
    text-align: center !important;
    line-height: 150% !important;
    font-weight: 700 !important;
    font-size: 18px !important;
  }
  #swal2-content {
    font-size: 10px !important;
    height: auto;
    display: block !important;
    position: absolute;
    right: 10px !important;
    left: 10px !important;
    width: auto;
    margin-top: 0 !important;
    text-align: center !important;
  }
  .swal2-images {
    display: block !important;
    width: 100%;
    height: 70% !important;
    margin-top: 0px !important;
    margin-bottom: 20px !important;
  }
  .swal2-cancel {
    width: auto;
    text-decoration: underline;
    font-size: 9px !important;
    margin-bottom: 15px;
    color: #bababa !important;
    background-color: rgba(255, 255, 255, 0.5) !important;
  }
  .swal2-cancel:hover,
  .swal2-cancel :focus {
    background: initial !important;
  }
  .swal2-close {
    font-size: 30px !important;
    position: absolute;
    top: 6px !important;
  }
  .swal2-actions {
    position: absolute;
    height: 100px;
    width: auto !important;
    display: block;
    right: 10px;
    left: 10px;
  }
  .tint_guide_button_pos {
    position: absolute;
    top: 30px;
    right: -5px;
  }
  .btn_position_mobile {
    margin-left: 85px;
  }
  .shop_search .search_bar {
    margin-left: 10px;
    width: 280px !important;
  }
  .img_section {
    margin-left: -10px;
    margin-right: 5px;
  }
  .shop_result_unit p.store {
    margin-top: 2px;
    font-size: 14px !important;
    font-weight: 700;
    line-height: 36px;
    cursor: pointer;
  }
  .shop_result_unit p.address {
    font-weight: 500;
    width: 170px !important;
    font-size: 13px !important;
  }
  .shop_result_unit .btn-block {
    position: relative;
    width: 45%;
    margin-left: 5px !important;
    margin-right: 5px;
  }
  .shop_result_unit .consulting_button {
    width: 45%;
    margin: 0px 0px 10px 10px;
  }
  .shop_result_unit .col-12 {
    padding-left: 0px !important;
  }
  .quote_title {
    font-size: 15px;
    margin-top: 30px;
  }
  .area_width_control {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    flex-grow: 0;
    flex-shrink: 0;
    width: 100%;
  }
  .package_area {
    margin-top: 50px;
  }
  .package_area .row {
    margin-left: 0px;
    margin-right: 0px;
  }
  .package_img_mobile {
    width: 140px !important;
  }
  .package_btn_mobile {
    width: 140px;
    height: 50px;
    line-height: 3;
    margin-right: 0px;
    margin-left: 0px;
    font-size: 16px !important;
  }
  .shop_container .shop_store_information {
    display: flex;
    margin-top: 55px;
    margin-bottom: 30px;
    margin-left: 20px;
  }
}
