.product_desktop {
  position: relative;
  .product_upper_section {
    margin: 0 auto;
    width: 1440px;
    .headline {
      width: 321px;
      height: 74px;
      font-family: 'Noto Sans KR', sans-serif;
      font-size: 50px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #00b5f0;
      margin: 100px 0px 90px 60px;
    }
    .why_tinting_container {
      display: flex;
      flex-wrap: wrap;
      .why_tinting_1 {
        width: 560px;
        height: 200px;
        margin: 0px 100px 50px 60px;
        .icon {
          width: 50px;
          height: 48px;
          object-fit: contain;
          margin: 0px 0px 10px 0px;
        }
        .title {
          width: auto;
          height: 45px;
          font-family: 'Noto Sans KR', sans-serif;
          font-size: 30px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #000000;
          margin: 0px 0px 20px 0px;
        }
        .text {
          width: auto;
          height: 72px;
          font-family: 'Noto Sans KR', sans-serif;
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #4c4c4c;
        }
      }
      .why_tinting_2 {
        width: 560px;
        height: 200px;
        margin: 0px 0px 50px 100px;

        .icon {
          width: 50px;
          height: 48px;
          object-fit: contain;
          margin: 0px 0px 10px 0px;
        }
        .title {
          width: auto;
          height: 45px;
          font-family: 'Noto Sans KR', sans-serif;
          font-size: 30px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #000000;
          margin: 0px 0px 20px 0px;
        }
        .text {
          width: auto;
          height: 72px;
          font-family: 'Noto Sans KR', sans-serif;
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #4c4c4c;
        }
      }
      .why_tinting_3 {
        width: 560px;
        height: 200px;
        margin: 0px 200px 50px 60px;
        .icon {
          width: 50px;
          height: 48px;
          object-fit: contain;
          margin: 0px 0px 10px 0px;
        }
        .title {
          width: auto;
          height: 45px;
          font-family: 'Noto Sans KR', sans-serif;
          font-size: 30px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #000000;
          margin: 0px 0px 20px 0px;
        }
        .text {
          width: auto;
          height: 72px;
          font-family: 'Noto Sans KR', sans-serif;
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #4c4c4c;
        }
      }
      .why_tinting_4 {
        width: 560px;
        height: 200px;
        .icon {
          width: 50px;
          height: 48px;
          object-fit: contain;
          margin: 0px 0px 10px 0px;
        }
        .title {
          width: auto;
          height: 45px;
          font-family: 'Noto Sans KR', sans-serif;
          font-size: 30px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #000000;
          margin: 0px 0px 20px 0px;
        }
        .text {
          width: auto;
          height: 72px;
          font-family: 'Noto Sans KR', sans-serif;
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #4c4c4c;
        }
      }
    }
  }
  .product_center_section {
    width: 1440px;
    margin: 100px auto 0px auto;
    position: relative;
    .main_image {
      width: 1440px;
      height: 738px;
      object-fit: contain;
    }
    .how_to_pick_container {
      position: absolute;
      top: 0px;
      width: 1440px;
      height: 738px;
      padding: 60px 60px 71px 60px;
      .headline {
        width: auto;
        height: 74px;
        font-family: 'Noto Sans KR', sans-serif;
        font-size: 50px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #ffffff;
        margin: 0px 0px 20px 0px;
      }
      .headline_sub {
        width: 560px;
        height: 48px;
        font-family: 'Noto Sans KR', sans-serif;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: justify;
        color: #ffffff;
      }
      .item_1 {
        display: inline-block;
        width: auto;
        height: 24px;
        font-family: 'Noto Sans KR', sans-serif;
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #ffffff;
        margin: 331px 238px 0px 0px;
      }
      .item_2 {
        display: inline-block;
        width: auto;
        height: 24px;
        font-family: 'Noto Sans KR', sans-serif;
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #ffffff;
        margin: 0px 239px 0px 0px;
      }
      .item_3 {
        display: inline-block;
        width: auto;
        height: 24px;
        font-family: 'Noto Sans KR', sans-serif;
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #ffffff;
        margin: 0px 253px 0px 0px;
      }
      .item_4 {
        display: inline-block;
        width: auto;
        height: 24px;
        font-family: 'Noto Sans KR', sans-serif;
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #ffffff;
      }
      .item_text_1 {
        display: inline-block;
        width: 200px;
        height: 80px;
        font-family: 'Noto Sans KR', sans-serif;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #ffffff;
        margin: 30px 173px 0px 0px;
      }
      .item_text_2 {
        display: inline-block;
        width: 200px;
        height: 80px;
        font-family: 'Noto Sans KR', sans-serif;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #ffffff;
        margin: 0px 174px 0px 0px;
      }
      .item_text_3 {
        display: inline-block;
        position: absolute;
        width: 200px;
        height: 60px;
        font-family: 'Noto Sans KR', sans-serif;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #ffffff;
        margin: 30px 173px 0px 0px;
      }
      .item_text_4 {
        display: inline-block;
        width: 200px;
        height: 80px;
        font-family: 'Noto Sans KR', sans-serif;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #ffffff;
        margin: 0px 0px 0px 373px;
      }
    }
  }
  .product_lower_section {
    margin: 0px auto 150px auto;
    width: 1440px;
    .premium_headline,
    .best_headline {
      width: auto;
      height: 74px;
      font-family: 'Noto Sans KR', sans-serif;
      font-size: 50px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #000000;
      margin: 120px 0px 41px 60px;
    }
    .premium_table,
    .best_table {
      margin: 0px 0px 0px 60px;
      width: 1316px;
      table {
        // width: 100%;
      }
      td {
        width: 175px;
        height: 40px;
        border: 1px #fff solid;
        font-family: 'Noto Sans KR', sans-serif;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 50px;
        letter-spacing: normal;
        color: #333333;
        text-indent: 20px;
      }
      .first_col {
        width: 170px;
        height: 40px;
        background-color: #e9ecef;
        border: 1px #fff solid;
        font-family: 'Noto Sans KR', sans-serif;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 50px;
        letter-spacing: normal;
        color: #333333;
        text-indent: 20px;
      }
      .first_row {
        width: 170px;
        height: 40px;
        background-color: #e9ecef;
        border: 1px #fff solid;
        font-family: 'Noto Sans KR', sans-serif;
        font-size: 14px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 50px;
        letter-spacing: normal;
        color: #4c4c4c;
        text-indent: 20px;
      }
    }
  }
}
